











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

@Component
export default class ToastComponet extends Vue {
  @Prop({ default: '' }) readonly text!: string
  @Prop({ default: null }) readonly options!: Dictionary<any> | null

  visible = true

  @Watch('visible')
  onChangeVisible(value: boolean) {
    if (!value) {
      setTimeout(() => {
        this.$destroy()
      }, 2000)
    }
  }
}
