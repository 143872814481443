var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"fixed-headers-container",style:({
    height: _vm.px(_vm.height),
    'max-height': _vm.px(_vm.height),
    'min-height': _vm.px(_vm.height),
  })},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left-container elevation-1",style:({
        height: ("calc(100% - " + (_vm.px(_vm.hScrollHeight)) + ")"),
        width: _vm.px(_vm.leftWidth),
      })},[_c('div',{staticClass:"horizontal-header elevation-1",style:({ height: _vm.headerHeight })},[_vm._t("left-header")],2),_c('div',{ref:"leftBody",staticClass:"left-body",style:({
          height: ("calc(100% - " + _vm.headerHeight + ")"),
        })},[_vm._t("left-body")],2)]),_c('div',{ref:"middleContainer",staticClass:"middle-container",style:({
        height: ("calc(100% - " + (_vm.px(_vm.hScrollHeight)) + ")"),
        width: ("calc(100% - " + (_vm.px(_vm.leftWidth)) + " - " + (_vm.px(_vm.rightWidth))),
      })},[_c('div',{staticClass:"horizontal-header elevation-1",style:({
          height: _vm.headerHeight,
          width: _vm.px(_vm.middleWidth),
        })},[_vm._t("header")],2),_c('div',{ref:"middleBody",staticClass:"middle-body",style:({
          height: ("calc(100% - " + _vm.headerHeight + ")"),
          width: ("calc(" + (_vm.px(_vm.middleWidth)) + ")"),
        })},[_vm._t("body")],2)]),_c('div',{staticClass:"right-container elevation-1",style:({
        height: ("calc(100% - " + (_vm.px(_vm.hScrollHeight)) + ")"),
        width: _vm.px(_vm.rightWidth),
      })},[_c('div',{staticClass:"horizontal-header elevation-1",style:({ height: _vm.headerHeight })},[_vm._t("right-header")],2),_c('div',{ref:"rightBody",staticClass:"right-body",style:({
          height: ("calc(100% - " + _vm.headerHeight + ")"),
        })},[_vm._t("right-body")],2)]),_c('div',{ref:"vscroll",staticClass:"vertical-scroll",style:({
        width: _vm.px(_vm.vScrollWidth),
        height: ("calc(100% - " + (_vm.px(_vm.hScrollHeight)) + " - " + _vm.headerHeight + ")"),
        top: _vm.headerHeight,
      })},[_c('div',{style:({ height: _vm.px(_vm.dataHeight) })})]),_c('div',{ref:"hscroll",staticClass:"horizontal-scroll",style:({
        height: _vm.px(_vm.hScrollHeight),
        width: "100%",
      })},[_c('div',{style:({
          width: ("calc(" + (_vm.px(_vm.leftWidth)) + " + " + (_vm.px(_vm.middleWidth)) + " + " + (_vm.px(
            _vm.rightWidth
          )) + " + " + (_vm.px(_vm.vScrollWidth)) + ")"),
        })})])])])}
var staticRenderFns = []

export { render, staticRenderFns }