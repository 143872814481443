import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Home from './views/Home.vue'
import store from './store'

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/Login.vue'),
  },
  {
    path: '/customer/create',
    name: 'customer-create',
    component: () =>
      import(/* webpackChunkName: "customer" */ './views/Customer/create.vue'),
  },
  {
    path: '/customer',
    name: 'customer-list',
    component: () =>
      import(/* webpackChunkName: "customer" */ './views/Customer/index.vue'),
    children: [
      {
        path: '/customer/:id/edit',
        name: 'customer-edit',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer" */ './views/Customer/edit.vue'
          ),
      },
      {
        path: '/customer/:id',
        name: 'customer-detail',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer" */ './views/Customer/detail.vue'
          ),
      },
    ],
  },
  {
    path: '/user',
    name: 'user-list',
    component: () =>
      import(/* webpackChunkName: "user" */ './views/User/index.vue'),
    children: [
      {
        path: '/user/:id',
        name: 'user-detail',
        props: true,
        component: () =>
          import(/* webpackChunkName: "user" */ './views/User/detail.vue'),
      },
    ],
  },
  {
    path: '/subscription',
    name: 'subscription-list',
    component: () =>
      import(
        /* webpackChunkName: "subscription" */ './views/Subscription/index.vue'
      ),
  },
  {
    path: '/reservation',
    name: 'reservation-list',
    component: () =>
      import(
        /* webpackChunkName: "reservation" */ './views/Reservation/index.vue'
      ),
  },
  {
    path: '/reservation_today',
    name: 'reservation-today',
    component: () =>
      import(
        /* webpackChunkName: "reservation" */ './views/Reservation/selectShop.vue'
      ),
  },
  {
    path: '/reservation_today/:shopId',
    name: 'reservation-today-shop',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "reservation" */ './views/Reservation/today.vue'
      ),
  },
  {
    path: '/timeline',
    name: 'timeline-shop-selection',
    component: () =>
      import(
        /* webpackChunkName: "timeline" */ './views/Timeline/ShopSelection.vue'
      ),
  },
  {
    path: '/timeline/:shopId',
    name: 'timeline-chart',
    props: true,
    component: () =>
      import(/* webpackChunkName: "timeline" */ './views/Timeline/Chart.vue'),
  },
  {
    path: '/kpi2',

    component: () =>
      import(/* webpackChunkName: "kpi" */ './views/KPI2/daily.vue'),
  },
  {
    path: '/kpi',
    component: () =>
      import(/* webpackChunkName: "kpi" */ './views/KPI/index.vue'),
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "kpi" */ './views/KPI/daily.vue'),
      },
      {
        path: 'monthly',
        component: () =>
          import(/* webpackChunkName: "kpi" */ './views/KPI/monthly.vue'),
      },
    ],
  },
  {
    path: '/logs',
    props: true,
    component: () =>
      import(/* webpackChunkName: "logs" */ './views/ReservationLog.vue'),
  },
  {
    path: '/card',
    component: () =>
      import(/* webpackChunkName: "timeline" */ './views/Dev.vue'),
  },
  {
    path: '/holiday',
    component: () =>
      import(/* webpackChunkName: "business_hours" */ './views/Holiday.vue'),
  },
  {
    path: '/openday',
    component: () =>
      import(/* webpackChunkName: "business_hours" */ './views/OpenDay.vue'),
  },
  {
    path: '/send_waku',
    component: () =>
      import(/* webpackChunkName: "business_hours" */ './views/Hotpepper.vue'),
  },
  {
    path: '/goods/purchase',
    component: () =>
      import(/* webpackChunkName: "goods" */ './views/Goods/Purchase.vue'),
  },
  {
    path: '/timecard',
    component: () =>
      import(/* webpackChunkName: "timecard" */ './views/TimeCard.vue'),
  },
  {
    path: '/overflow',
    component: () =>
      import(/* webpackChunkName: "overflow" */ './views/OverflowKPI.vue'),
  },
  {
    path: '/shift',
    component: () =>
      import(/* webpackChunkName: "shift" */ './views/Shift.vue'),
  },
  {
    path: '/previous_color',
    component: () =>
      import(/* webpackChunkName: "prev" */ './views/PreviousColor/index.vue'),
  },
  {
    path: '/previous_color/:shopId',
    props: true,
    component: () =>
      import(/* webpackChunkName: "prev" */ './views/PreviousColor/detail.vue'),
  },

  {
    path: '/ec_user',
    component: () => import(/* webpackChunkName: "ec" */ './views/ECUser.vue'),
  },
  {
    path: '/additional_waku',
    component: () =>
      import(/* webpackChunkName: "ec" */ './views/ForceReservable.vue'),
  },
  {
    path: '/merge_customer',
    component: () =>
      import(/* webpackChunkName: "customer" */ './views/CustomerMerge.vue'),
  },
  {
    path: '/used_ticket',
    component: () =>
      import(/* webpackChunkName: "ticket" */ './views/UsedTicket.vue'),
  },
  {
    path: '/purchased_ticket',
    component: () =>
      import(/* webpackChunkName: "ticket" */ './views/PurchasedTicket.vue'),
  },
  {
    path: '/firebase_user',
    component: () =>
      import(/* webpackChunkName: "user" */ './views/FirebaseUser/index.vue'),
  },
  {
    path: '/firebase_user/:uid',
    component: () =>
      import(/* webpackChunkName: "user" */ './views/FirebaseUser/detail.vue'),
  },
  {
    path: '/auth',
    async beforeEnter(to, from, next) {
      const api = Vue.prototype.$api
      const token = to.query.token
      const path = (to.query.next || '/') as string
      if (!token) {
        next({ path: '/login', query: { next: path } })
      } else {
        try {
          let data = await api.auth.refreshToken({ token })
          store.commit('LOGIN', data)
          next(path)
        } catch (err) {
          next({ path: '/login', query: { next: path } })
        }
      }
    },
  },
]

export default new Router({
  routes,
})
