var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.fixHeight),expression:"fixHeight"}],staticClass:"virtual-data-table",attrs:{"tile":"","width":"100%","height":"100%"}},[_vm._t("top",null,{"items":_vm.items}),_c('DataContainer',{ref:"container",attrs:{"height":Number(_vm.tableHeight),"middle-width":_vm.middleWidth,"left-width":_vm.leftWidth,"data-height":48 * _vm.items.length + 48,"header-height":"56px"}},[_vm._l(([
        { columns: _vm.leftColumns, slot: 'left-header' },
        { columns: _vm.middleColumns, slot: 'header' } ]),function(ref){
      var slot = ref.slot;
      var columns = ref.columns;
return [_c('v-layout',{key:slot,staticClass:"head",attrs:{"slot":slot},slot:slot},_vm._l((columns),function(col){return _c('HeaderCell',{key:col.key,staticClass:"th",style:({
            width: _vm.px(col.width),
            'max-width': _vm.px(col.width),
            'min-width': _vm.px(col.width),
          }),attrs:{"column":col,"ordering":_vm.ordering},on:{"order":function($event){return _vm.$emit('update:ordering', $event)}}},[_vm._t(("header." + (col.prop)),[_vm._v(_vm._s(col.text))])],2)}),1)]}),_vm._l(([
        { columns: _vm.leftColumns, slot: 'left-body' },
        { columns: _vm.middleColumns, slot: 'body' } ]),function(ref){
      var slot = ref.slot;
      var columns = ref.columns;
return [_c('RecycleScroller',{key:slot,staticClass:"scroller",attrs:{"slot":slot,"items":_vm.wrappedItems,"size-field":"size","key-field":"key"},slot:slot,scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-layout',{class:{ tr: true, selected: props.item.selected },on:{"click":function($event){return _vm.$emit('click-row', props.item)}}},_vm._l((columns),function(col){return _c('v-layout',{key:col.key,staticClass:"td",style:({
                width: _vm.px(col.width),
                'max-width': _vm.px(col.width),
                'min-width': _vm.px(col.width),
                height: _vm.px(props.item.size),
              }),attrs:{"align-center":""}},[_c('TableCell',{staticClass:"text-truncate",attrs:{"column":col,"row":props.item}})],1)}),1)]}},(!_vm.disableInfinite)?{key:"after",fn:function(){return [_c('v-layout',{staticClass:"pl-5",staticStyle:{"height":"48px"}},[(slot === 'body')?_c('InfiniteLoading',{attrs:{"identifier":_vm.identifier,"force-use-infinite-wrapper":".scroller"},on:{"infinite":function($event){return _vm.$emit('infinite', $event)}}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v("該当するデータはありません")])]):_vm._e()],1)]},proxy:true}:null],null,true)})]})],2),_c('v-layout',{ref:"footer",attrs:{"column":""}},[_c('v-flex',[(_vm.totalCount !== null)?_c('span',[_vm._v("全 "+_vm._s(_vm._f("locale")(_vm.totalCount))+"件 ")]):_vm._e(),(!_vm.disableInfinite)?_c('span',[_vm._v(" 読込済 "+_vm._s(_vm.items.length)+" 件 ")]):_vm._e()]),_c('v-flex',[_vm._t("bottom")],2)],1),_c('div',{staticStyle:{"display":"none"}},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }